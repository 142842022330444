import React from "react";

export const Illustration3 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="156" viewBox="0 0 200 156" fill="none">
            <rect x="44" y="21" width="82" height="110" rx="8" fill="#666666"/>
            <rect x="48" y="27" width="74" height="89" rx="6" fill="#BBBBBB"/>
            <rect x="57" y="41" width="116" height="76" rx="4" fill="#32393E"/>
            <path d="M57 45C57 42.7909 58.7909 41 61 41H169C171.209 41 173 42.7909 173 45V55H57V45Z" fill="#FE9235"/>
            <circle cx="63.5" cy="47.5" r="2.5" fill="#FFF9F1"/>
            <circle cx="70.5" cy="47.5" r="2.5" fill="#FFF9F1"/>
            <circle cx="77.5" cy="47.5" r="2.5" fill="#FFF9F1"/>
            <circle cx="115" cy="83" r="17" fill="#555555"/>
            <path d="M123 83L111 89.9282L111 76.0718L123 83Z" fill="white"/>
            <rect x="74" y="24" width="22" height="2" rx="1" fill="#BBBBBB"/>
            <circle cx="85" cy="123" r="4" fill="#BBBBBB"/>
            <path
                d="M74.9935 89.3954L59.2925 59.7112C59.2925 59.7112 63.7934 58.6616 67.4989 65.9882C71.2253 73.3148 79.9341 89.5004 79.9341 89.5004L74.9935 89.3954Z"
                fill="#EEEEEE"/>
            <rect x="17" y="44" width="50" height="92" fill="#157BF2"/>
            <rect x="24" y="44" width="36" height="92" fill="#299BEF"/>
            <rect x="19" y="46" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="51" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="56" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="61" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="66" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="71" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="76" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="81" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="86" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="91" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="96" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="101" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="106" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="111" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="116" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="121" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="126" width="3" height="3" rx="1" fill="white"/>
            <rect x="19" y="131" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="46" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="51" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="56" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="61" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="66" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="71" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="76" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="81" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="86" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="91" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="96" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="101" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="106" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="111" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="116" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="121" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="126" width="3" height="3" rx="1" fill="white"/>
            <rect x="62" y="131" width="3" height="3" rx="1" fill="white"/>
            <rect x="24" y="74" width="36" height="1" fill="#EEF8FF"/>
            <rect x="24" y="105" width="36" height="1" fill="#EEF8FF"/>
            <rect x="27.5" y="47.5" width="29" height="23" stroke="#EEF8FF"/>
            <rect x="27.5" y="78.5" width="29" height="23" stroke="#EEF8FF"/>
            <rect x="27.5" y="109.5" width="29" height="23" stroke="#EEF8FF"/>
            <path
                d="M43.5391 65H41.6953V57.6484C41.6953 57.4036 41.6979 57.1589 41.7031 56.9141C41.7083 56.6693 41.7135 56.4297 41.7188 56.1953C41.7292 55.9609 41.7422 55.737 41.7578 55.5234C41.6484 55.6432 41.513 55.7734 41.3516 55.9141C41.1953 56.0547 41.0286 56.2005 40.8516 56.3516L39.5391 57.3984L38.6172 56.2344L42.0078 53.5781H43.5391V65Z"
                fill="white"/>
            <path
                d="M45.875 96H38.1406V94.6172L41.1016 91.6172C41.6745 91.0339 42.1458 90.5312 42.5156 90.1094C42.8854 89.6823 43.1589 89.2786 43.3359 88.8984C43.5182 88.5182 43.6094 88.1042 43.6094 87.6562C43.6094 87.1042 43.4505 86.6875 43.1328 86.4062C42.8151 86.1198 42.3958 85.9766 41.875 85.9766C41.3802 85.9766 40.9219 86.0755 40.5 86.2734C40.0833 86.4661 39.651 86.7422 39.2031 87.1016L38.1953 85.8906C38.513 85.6198 38.8516 85.3724 39.2109 85.1484C39.5755 84.9245 39.9818 84.7474 40.4297 84.6172C40.8776 84.4818 41.388 84.4141 41.9609 84.4141C42.6849 84.4141 43.3099 84.5443 43.8359 84.8047C44.362 85.0599 44.7656 85.4167 45.0469 85.875C45.3333 86.3333 45.4766 86.8672 45.4766 87.4766C45.4766 88.0911 45.3542 88.6589 45.1094 89.1797C44.8646 89.7005 44.5156 90.2161 44.0625 90.7266C43.6094 91.2318 43.0703 91.7812 42.4453 92.375L40.4688 94.3047V94.3906H45.875V96Z"
                fill="white"/>
            <path
                d="M45.4375 118.188C45.4375 118.703 45.3333 119.151 45.125 119.531C44.9167 119.911 44.6302 120.224 44.2656 120.469C43.9062 120.708 43.4896 120.88 43.0156 120.984V121.039C43.9271 121.154 44.6146 121.44 45.0781 121.898C45.5469 122.357 45.7812 122.964 45.7812 123.719C45.7812 124.38 45.6224 124.971 45.3047 125.492C44.987 126.008 44.5 126.414 43.8438 126.711C43.1875 127.008 42.3438 127.156 41.3125 127.156C40.6927 127.156 40.1172 127.107 39.5859 127.008C39.0599 126.909 38.5599 126.75 38.0859 126.531V124.898C38.5703 125.138 39.0859 125.323 39.6328 125.453C40.1797 125.583 40.6979 125.648 41.1875 125.648C42.1406 125.648 42.8203 125.471 43.2266 125.117C43.6328 124.758 43.8359 124.263 43.8359 123.633C43.8359 123.227 43.7292 122.893 43.5156 122.633C43.3073 122.372 42.9818 122.177 42.5391 122.047C42.1016 121.917 41.5365 121.852 40.8438 121.852H39.8359V120.375H40.8516C41.513 120.375 42.0443 120.297 42.4453 120.141C42.8464 119.979 43.1354 119.758 43.3125 119.477C43.4948 119.195 43.5859 118.867 43.5859 118.492C43.5859 118.003 43.4271 117.622 43.1094 117.352C42.7917 117.076 42.3203 116.938 41.6953 116.938C41.3099 116.938 40.9583 116.982 40.6406 117.07C40.3281 117.159 40.0391 117.271 39.7734 117.406C39.5078 117.542 39.2552 117.688 39.0156 117.844L38.1328 116.57C38.5651 116.247 39.0807 115.974 39.6797 115.75C40.2786 115.526 40.9766 115.414 41.7734 115.414C42.9453 115.414 43.849 115.667 44.4844 116.172C45.1198 116.672 45.4375 117.344 45.4375 118.188Z"
                fill="white"/>
            <path
                d="M99.6546 124.118C100.324 127.456 102.083 131.801 102.188 134.111C102.209 134.467 102.146 134.614 100.973 134.887C99.8011 135.16 100.869 135.916 102.879 136C104.888 136.084 104.281 126.364 103.527 121.788C102.858 117.778 98.88 120.255 99.6546 124.118Z"
                fill="#FFB69E"/>
            <path
                d="M110.876 103.188C110.876 103.188 100.534 110.451 99.0475 114.188C97.7077 117.547 100.806 131.025 100.806 131.025C100.806 131.025 102.983 131.885 104.993 130.668C104.993 130.668 103.695 122.585 104.721 120.192C105.872 117.463 113.451 112.194 113.451 112.194L110.876 103.188Z"
                fill="#55C7A6"/>
            <path
                d="M116.067 126.973C118.956 129.198 123.415 131.466 125.111 133.229C125.383 133.502 125.446 133.649 124.672 134.719C123.918 135.769 125.3 135.601 126.974 134.258C128.649 132.914 121.343 125.503 117.533 122.355C114.204 119.604 112.739 124.391 116.067 126.973Z"
                fill="#FFB69E"/>
            <path
                d="M112.404 102.915C112.404 102.915 107.045 115.175 108.447 119.206C109.724 122.837 121.678 131.508 121.678 131.508C121.678 131.508 124.043 130.668 124.797 128.275C124.797 128.275 118.935 122.481 118.328 119.73C117.617 116.519 120.254 110.682 118.768 107.135C118.726 107.03 112.404 102.915 112.404 102.915Z"
                fill="#55C7A6"/>
            <path
                d="M107.275 93.4262C106.814 93.2162 104.742 94.035 103.967 95.2316C102.899 96.827 103.088 99.7871 100.722 100.963C98.3357 102.138 96.8493 101.131 95.7817 100.585C94.714 100.039 94.5256 99.3252 93.8557 99.0733C93.1858 98.8214 92.9345 99.6401 94.4628 100.921C95.1955 101.529 98.4194 105.014 101.518 104.154C105.558 103.02 109.996 94.6858 107.275 93.4262Z"
                fill="#FFB69E"/>
            <path
                d="M118.6 106.736C118.621 106.736 118.621 106.736 118.6 106.736C118.621 106.715 118.621 106.736 118.6 106.736C118.559 106.652 118.496 106.589 118.454 106.505C116.528 103.293 115.167 101.299 115.272 97.4988C115.439 92.0406 111.441 92.5234 108.991 92.7543C106.542 92.9853 102.69 94.1189 102.795 98.4854C102.858 101.634 103.988 105.707 105.956 108.31C108.217 111.291 121.322 110.074 118.6 106.736Z"
                fill="#55C7A6"/>
            <path
                d="M98.0217 105.854C98.0635 102.726 97.5192 100.207 88.936 97.1209C85.7121 95.9663 81.232 93.3421 79.4107 87.2961L74.4911 88.8916C74.4911 88.8916 79.2642 109.318 87.4496 112.719C93.5416 115.28 97.9798 111.249 98.0217 105.854ZM90.1712 111.186C86.9681 110.724 84.9794 104.573 83.7861 101.97C82.5928 99.3672 84.6025 99.3252 84.6025 99.3252C86.4657 99.3672 90.5061 101.424 93.5416 103.755C96.5562 106.106 93.3742 111.648 90.1712 111.186Z"
                fill="#FE9235"/>
            <path
                d="M79.8085 83.2443L48.1971 71.1523C48.1971 71.1523 47.6528 75.6868 55.4405 78.5209C63.2282 81.355 80.4784 88.1147 80.4784 88.1147L79.8085 83.2443Z"
                fill="white"/>
            <path
                d="M104.218 97.6036C107.296 93.5939 109.954 81.208 91.9925 81.376C88.8104 81.4179 83.3465 83.8321 79.3061 81.8588V88.0937C79.3061 88.0727 97.7914 105.98 104.218 97.6036ZM87.8055 91.3057C87.8055 91.3057 82.9696 85.9315 89.4594 84.8608C89.4594 84.8608 94.9024 83.6642 99.6127 86.1624C104.323 88.6396 104.511 94.0348 101.539 96.5749C98.7753 98.9262 95.0699 97.1837 87.8055 91.3057Z"
                fill="#FE9235"/>
            <path
                d="M114.665 94.2447C114.267 93.8039 112.885 93.489 111.378 94.3497C109.368 95.4833 106.689 98.8002 103.465 98.8422C100.22 98.9052 99.1312 97.1418 98.231 96.0711C97.3308 94.9795 97.5192 94.1398 96.9331 93.5729C96.326 93.0061 95.6351 93.6989 96.5981 95.7772C97.0796 96.7639 98.7753 101.928 102.627 102.516C107.673 103.314 116.989 96.8479 114.665 94.2447Z"
                fill="#FFB69E"/>
            <path
                d="M108.615 89.5632C108.615 89.5632 108.07 91.6415 109.599 92.3973C111.169 93.174 106.375 97.0158 106.291 95.8821C106.186 94.4126 106.772 93.426 105.768 91.8935L108.615 89.5632Z"
                fill="#FFB69E"/>
            <path
                d="M104.93 86.4983C103.925 87.8838 103.548 93.1951 105.035 94.0768C105.684 94.4547 107.882 93.489 108.908 90.9698C109.326 89.9622 109.766 88.0938 108.51 86.5403C107.966 85.8475 106.102 84.9028 104.93 86.4983Z"
                fill="#FFB69E"/>
            <path
                d="M103.8 87.3381C103.653 88.0309 103.758 88.1568 105.705 88.0939C107.673 88.0309 107.966 87.8419 107.861 88.3458C107.547 89.7943 108.845 89.2695 108.447 90.9909C108.049 92.7123 109.473 91.4528 109.934 90.0252C110.038 89.7313 110.582 88.3038 109.305 86.4774C107.777 84.3571 104.365 84.8399 103.8 87.3381Z"
                fill="#32393E"/>
        </svg>
    )
}
