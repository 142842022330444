import React from "react";

export const Illustration1 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="156" viewBox="0 0 200 156" fill="none">
            <rect x="30" y="36" width="50" height="98" fill="#157BF2"/>
            <rect x="37" y="36" width="36" height="98" fill="#299BEF"/>
            <rect x="32" y="38" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="43" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="48" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="53" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="58" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="63" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="68" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="73" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="78" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="83" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="88" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="93" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="98" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="103" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="108" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="113" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="118" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="123" width="3" height="3" rx="1" fill="white"/>
            <rect x="32" y="128" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="38" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="43" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="48" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="53" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="58" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="63" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="68" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="73" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="78" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="83" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="88" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="93" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="98" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="103" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="108" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="113" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="118" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="123" width="3" height="3" rx="1" fill="white"/>
            <rect x="75" y="128" width="3" height="3" rx="1" fill="white"/>
            <rect x="37" y="66" width="36" height="1" fill="#EEF8FF"/>
            <rect x="47.1213" y="41" width="24" height="3" transform="rotate(45 47.1213 41)" fill="#EEF8FF"/>
            <rect x="45" y="57.9705" width="24" height="3" transform="rotate(-45 45 57.9705)" fill="#EEF8FF"/>
            <rect x="47.1213" y="74" width="24" height="3" transform="rotate(45 47.1213 74)" fill="#EEF8FF"/>
            <rect x="45" y="90.9705" width="24" height="3" transform="rotate(-45 45 90.9705)" fill="#EEF8FF"/>
            <rect x="47.1213" y="109" width="24" height="3" transform="rotate(45 47.1213 109)" fill="#EEF8FF"/>
            <rect x="45" y="125.97" width="24" height="3" transform="rotate(-45 45 125.97)" fill="#EEF8FF"/>
            <rect x="37" y="101" width="36" height="1" fill="#EEF8FF"/>
            <path d="M173 128H67V134H173V128Z" fill="#FE9235"/>
            <path
                d="M142 134H64.5L51.8661 89.17C50.4276 84.0658 54.2631 79 59.5662 79H119.099C122.608 79 125.706 81.2857 126.742 84.6375L142 134Z"
                fill="#FE9235"/>
            <path d="M132.542 19.081L116.937 57.7419L161.395 75.6871L177 37.0262L132.542 19.081Z" fill="#157BF2"/>
            <path d="M128.723 36.5293L120.954 55.7695L158.939 71.1058L166.707 51.8655L128.723 36.5293Z" fill="#F5F9FC"/>
            <path
                d="M159.435 39.7717C158.204 42.851 159.672 46.333 162.75 47.5647C165.829 48.7964 169.31 47.3278 170.565 44.2485C171.796 41.1693 170.328 37.6873 167.25 36.4319C164.171 35.2002 160.667 36.6924 159.435 39.7717Z"
                fill="#F5F9FC"/>
            <path d="M132.363 27.5194L131.698 29.166L155.878 38.9288L156.543 37.2822L132.363 27.5194Z" fill="#F5F9FC"/>
            <path d="M133.717 24.2028L133.052 25.8494L157.233 35.6095L157.897 33.9629L133.717 24.2028Z" fill="#F5F9FC"/>
            <path d="M131.007 30.8512L130.342 32.4978L154.522 42.2579L155.187 40.6114L131.007 30.8512Z" fill="#F5F9FC"/>
            <path
                d="M158 69L124 55.0323L135.117 44L139.223 55.5704L150.244 47.4002L153.534 59.2153L157.616 57.8454L158 69Z"
                fill="#55C7A6"/>
            <path
                d="M127.053 126.187C127.571 130.336 129.266 135.827 129.195 138.635C129.195 139.082 129.101 139.256 127.712 139.48C126.324 139.728 127.524 140.722 129.855 140.995C132.185 141.269 132.256 129.317 131.738 123.628C131.291 118.683 126.441 121.391 127.053 126.187Z"
                fill="#FFB69E"/>
            <path
                d="M112.727 106.961C114.829 110.636 118.687 115.125 119.645 117.764C119.805 118.184 119.778 118.356 118.395 119.096C117.011 119.836 118.661 120.329 121.215 119.737C123.797 119.121 119.406 107.997 116.745 102.891C114.43 98.4272 110.332 102.719 112.727 106.961Z"
                fill="#FFB69E"/>
            <path
                d="M119.7 95.0055C119.7 95.0055 110.076 90.3966 109.075 95.1526C108.146 99.4182 116.182 114.912 116.182 114.912C116.182 114.912 118.967 115.5 121.092 113.637L118.942 103.733L125 103.586L123.608 96.7461L119.7 95.0055Z"
                fill="#252D5C"/>
            <path d="M133 99.325L132.276 136L128.21 135.875L123 98C123 98.025 126.037 99.8 133 99.325Z" fill="#252D5C"/>
            <path
                d="M123.309 86C123.309 86 127.14 84.9679 127.14 80.4215C127.14 75.8998 124.378 65.5293 124.43 63.8336C124.534 60.7618 129.433 60.5652 127.583 60.4178C125.185 60.2212 123.674 59.1399 123.101 61.4991C122.71 63.121 120.807 78.6276 121.016 81.2571C121.224 83.9112 120.599 85.9017 123.309 86Z"
                fill="#FFB69E"/>
            <path
                d="M133.359 100.082C133.359 99.9588 133.334 99.8601 133.334 99.7368C133.066 95.2714 132.7 92.385 134.92 88.413C138.066 82.7141 133.603 80.9872 130.895 79.9017C128.188 78.7915 123.529 77.854 121.236 82.5661C119.602 85.9459 118.553 90.88 119.187 94.7533C119.919 99.1447 134.383 105.115 133.359 100.082Z"
                fill="#252D5C"/>
            <path
                d="M130.654 88.3597C130.654 88.3597 133.964 90.4138 137.375 87.1637C140.787 83.9136 147.71 74.6054 148.215 72.8893C148.721 71.1993 153.976 73.2274 152.839 71.7713C151.677 70.3413 151.121 69.3013 148.973 70.5753C147.483 71.4593 134.545 81.2356 132.701 83.2636C130.882 85.3177 128.961 86.3057 130.654 88.3597Z"
                fill="#FFB69E"/>
            <path
                d="M135 78.0071C135 78.0071 132.546 78.7052 132.594 81.1196C132.643 83.5921 126.009 80.1596 127.127 79.3161C128.609 78.2398 129.946 78.2689 130.966 76L135 78.0071Z"
                fill="#FFB69E"/>
            <path
                d="M135.833 72.0237C133.832 71.7196 128.098 74.4054 128 76.5338C127.976 77.446 130.221 79.2703 133.393 78.9662C134.71 78.8395 136.858 78.2061 137.785 75.9764C138.224 74.9375 138.175 72.3784 135.833 72.0237Z"
                fill="#FFB69E"/>
            <path
                d="M134.897 71.1363C134.141 71.3803 134.072 71.5971 135.172 73.8197C136.271 76.0695 136.614 76.2863 136.065 76.4761C134.485 76.9911 135.676 78.1837 133.775 78.8071C131.874 79.4034 133.866 80.2708 135.515 79.9184C135.836 79.8371 137.53 79.5932 138.63 76.964C139.912 73.9282 137.668 70.2418 134.897 71.1363Z"
                fill="#32393E"/>
            <path
                d="M138.673 76.9674C137.749 79.5171 135.856 79.8426 135.533 79.9239C134.286 80.1681 132.831 79.7883 133.016 79.3001C133.224 78.7033 134.217 79.8426 134.979 78.5406C135.741 77.2386 136.479 77.8354 137.218 76.8318C137.957 75.8281 137.288 75.2314 136.041 72.6817C135.533 71.651 135.717 71.1899 136.11 71C138.28 71.2984 139.666 74.2821 138.673 76.9674Z"
                fill="#32393E"/>
        </svg>
    )
}
