import React from "react";

export const Illustration2 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="156" viewBox="0 0 200 156" fill="none">
            <rect x="96" y="26" width="75" height="71" fill="#157BF2"/>
            <rect x="106" y="26" width="55" height="71" fill="#299BEF"/>
            <circle cx="134" cy="62" r="22.5" stroke="white"/>
            <path
                d="M136 68H133.017V59.8988C133.017 59.6753 133.02 59.4008 133.026 59.0752C133.033 58.7433 133.042 58.4049 133.055 58.0602C133.068 57.7091 133.081 57.3931 133.094 57.1122C133.023 57.1952 132.878 57.3388 132.659 57.5431C132.447 57.741 132.247 57.9197 132.061 58.0793L130.439 59.3721L129 57.591L133.548 54H136V68Z"
                fill="white"/>
            <rect x="99" y="28" width="4" height="4" rx="1" fill="white"/>
            <rect x="99" y="35" width="4" height="4" rx="1" fill="white"/>
            <rect x="99" y="42" width="4" height="4" rx="1" fill="white"/>
            <rect x="99" y="49" width="4" height="4" rx="1" fill="white"/>
            <rect x="99" y="56" width="4" height="4" rx="1" fill="white"/>
            <rect x="99" y="63" width="4" height="4" rx="1" fill="white"/>
            <rect x="99" y="70" width="4" height="4" rx="1" fill="white"/>
            <rect x="99" y="77" width="4" height="4" rx="1" fill="white"/>
            <rect x="99" y="84" width="4" height="4" rx="1" fill="white"/>
            <rect x="99" y="91" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="28" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="35" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="42" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="49" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="56" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="63" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="70" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="77" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="84" width="4" height="4" rx="1" fill="white"/>
            <rect x="164" y="91" width="4" height="4" rx="1" fill="white"/>
            <path
                d="M36.7645 65.7717L36.0186 65.9783C36.0186 65.9783 36.1729 66.4688 36.3015 66.9852C36.4558 67.4757 36.6102 67.9663 36.6102 67.9663L37.3561 67.7081C37.3561 67.7081 37.2017 67.2175 37.0474 66.7528C36.9188 66.2623 36.7645 65.7717 36.7645 65.7717Z"
                fill="#7AC5EE"/>
            <path d="M36.07 62.8286L35.2984 62.9835L35.7614 64.9973L36.533 64.8166L36.07 62.8286Z" fill="#7AC5EE"/>
            <path
                d="M35.5556 59.8337L34.784 59.937C34.784 59.937 34.8612 60.4534 34.9384 60.9697C35.0412 61.4861 35.1184 61.9766 35.1184 61.9766L35.89 61.8217C35.89 61.8217 35.7871 61.3312 35.71 60.8148C35.6328 60.3501 35.5556 59.8337 35.5556 59.8337Z"
                fill="#7AC5EE"/>
            <path
                d="M141 140.145C141 142.822 138.877 145 136.268 145H38.7322C36.1231 145 34 142.822 34 140.145V83.8549C34 81.1781 36.1231 79 38.7322 79H136.268C138.877 79 141 81.1781 141 83.8549V140.145Z"
                fill="#FE9235"/>
            <path d="M141.37 77.4414H33.7809V94.9718H141.37V77.4414Z" fill="#32393E"/>
            <path d="M33.7809 77.4414V94.9718H34.4754L47.85 77.4414H33.7809Z" fill="#BBBBBB"/>
            <path d="M120.511 77.4414L107.136 94.9718H120.228L133.602 77.4414H120.511Z" fill="#BBBBBB"/>
            <path d="M63.3338 77.4414L49.9849 94.9718H63.0766L76.4256 77.4414H63.3338Z" fill="#BBBBBB"/>
            <path d="M91.9093 77.4414L78.5604 94.9718H91.6521L105.001 77.4414H91.9093Z" fill="#BBBBBB"/>
            <path d="M141.37 87.5879L135.737 94.9718H141.37V87.5879Z" fill="#BBBBBB"/>
            <path
                d="M126.349 29.5751L33.2665 60.5049C30.7716 61.3311 29.4084 64.042 30.2315 66.5463L34.3468 79.0422L136.483 45.0917L132.368 32.6216C131.545 30.0915 128.87 28.7489 126.349 29.5751Z"
                fill="#32393E"/>
            <path d="M52.737 54.0505L44.6864 75.6085L57.3667 71.4002L65.4172 49.8164L52.737 54.0505Z" fill="#BBBBBB"/>
            <path
                d="M33.2665 60.5048C30.7716 61.331 29.4084 64.0419 30.2315 66.5462L32.4177 73.2072L37.7162 59.0332L33.2665 60.5048Z"
                fill="#BBBBBB"/>
            <path d="M80.4123 44.8336L72.3617 66.4173L85.042 62.1832L93.0925 40.6252L80.4123 44.8336Z" fill="#BBBBBB"/>
            <path d="M132.882 34.1448L127.712 48.009L136.509 45.0916L132.882 34.1448Z" fill="#BBBBBB"/>
            <path d="M108.088 35.6424L100.037 57.2003L112.717 52.992L120.768 31.4341L108.088 35.6424Z" fill="#BBBBBB"/>
            <path
                d="M131 107.5C131 108.324 130.43 109 129.736 109H44.2642C43.5701 109 43 108.324 43 107.5C43 106.676 43.5701 106 44.2642 106H129.711C130.43 106 131 106.676 131 107.5Z"
                fill="#FE7426"/>
            <path
                d="M131 118.5C131 119.324 130.43 120 129.736 120H44.2642C43.5701 120 43 119.324 43 118.5C43 117.676 43.5701 117 44.2642 117H129.711C130.43 117 131 117.647 131 118.5Z"
                fill="#FE7426"/>
            <path
                d="M131 129.5C131 130.324 130.43 131 129.736 131H44.2642C43.5701 131 43 130.324 43 129.5C43 128.676 43.5701 128 44.2642 128H129.711C130.43 128 131 128.676 131 129.5Z"
                fill="#FE7426"/>
            <path
                d="M56.0387 128.084C54.9436 134.56 54.0261 148.345 56.897 148.226C59.7679 148.137 61.3069 147.068 59.6199 146.682C57.9329 146.266 57.8441 146.087 57.8737 145.553C58.0217 142.314 60.567 136.135 61.5437 131.411C62.6684 125.945 56.9858 122.409 56.0387 128.084Z"
                fill="#FFB69E"/>
            <path
                d="M58.6136 104.97C58.6136 104.97 59.9159 107.644 60.4487 110.199C62.2245 118.874 62.1653 119.943 62.1949 122.409C62.2541 126.598 62.402 134.709 59.1464 141.809C58.8208 142.493 54.5885 142.552 54.4109 141.631C53.2566 134.887 54.1445 134.144 53.4342 126.361C53.2566 124.251 52.3095 120.567 51.54 118.161C49.3498 111.358 49.705 110.229 50.8001 106.782L58.6136 104.97Z"
                fill="#157BF2"/>
            <path
                d="M69.4461 112.189C68.9725 118.755 69.3573 132.54 72.2282 132.184C75.0695 131.797 76.5197 130.609 74.8031 130.371C73.0865 130.134 72.9977 129.955 72.9681 129.421C72.7905 126.182 74.7439 119.795 75.2767 114.982C75.8686 109.456 69.8604 106.456 69.4461 112.189Z"
                fill="#FFB69E"/>
            <path
                d="M62.3725 104.227C62.3725 104.227 71.6955 97.3943 74.8623 102.118C77.7332 106.366 73.6489 126.004 73.6489 126.004C73.6489 126.004 71.4291 126.895 69.2685 125.677C69.2685 125.677 69.7421 113.705 67.1672 113.378C64.2667 113.021 58.3473 117.626 55.1804 115.457C50.2378 112.071 54.4997 104.435 54.4997 104.435L62.3725 104.227Z"
                fill="#157BF2"/>
            <path
                d="M64.0891 76.1525C64.0891 76.1525 66.2201 71.8447 66.5752 73.3004C66.9304 74.7264 66.0721 74.5185 65.3618 76.7466L64.0891 76.1525Z"
                fill="#FFB69E"/>
            <path
                d="M49.8234 90.7099C49.2907 91.3338 48.4915 93.4728 50.2674 93.8591C53.0495 94.4829 59.7383 90.6505 61.1886 89.1353C64.3259 85.8674 65.5097 76.4794 65.5097 76.4794C65.5097 76.4794 64.8586 75.4396 63.8227 76.0337L57.4298 85.9862C57.4594 86.0159 51.6288 88.6006 49.8234 90.7099Z"
                fill="#157BF2"/>
            <path
                d="M58.9097 88.4224C61.2478 89.8188 62.7277 89.6702 65.0658 102.534C65.569 105.357 49.7643 111.774 49.3795 108.951C48.3732 101.376 49.3203 98.0778 50.741 91.0071C51.1553 89.076 54.3222 85.6595 58.9097 88.4224Z"
                fill="#157BF2"/>
            <path
                d="M74.5663 78.0241C74.5663 78.0241 75.0103 74.0134 75.8982 75.0829C76.7861 76.1525 76.5789 76.6872 75.691 78.7074L74.5663 78.0241Z"
                fill="#FFB69E"/>
            <path
                d="M59.9751 90.3531C59.4423 90.8582 57.4889 94.1856 59.0872 94.6906C61.6325 95.5225 67.6998 93.7994 71.2515 90.7393C76.1646 86.5207 75.8982 78.4993 75.8982 78.4993C75.8982 78.4993 75.395 77.4892 74.3591 77.9348C74.3591 77.9348 69.4757 84.649 67.2263 86.2533C65.5097 87.4714 61.8397 88.5409 59.9751 90.3531Z"
                fill="#157BF2"/>
            <path
                d="M55.95 76.0932C57.7554 76.1229 60.1528 77.3707 60.5079 80.8764C60.8927 84.382 60.9815 84.4711 62.3133 85.6298C65.717 88.6304 59.6496 90.4723 62.4909 93.3244C73.4122 104.168 50.1786 108.535 50.7114 102.623C51.4217 94.9287 46.6862 98.7908 47.0414 94.0671C47.1893 92.1954 44.2888 90.4426 47.5741 87.7391C48.6692 86.8478 46.1239 82.9263 48.8764 81.827C51.6585 80.7278 48.9356 75.9447 55.95 76.0932Z"
                fill="#32393E"/>
            <rect x="106" y="61" width="5" height="1" fill="white"/>
            <rect x="156" y="61" width="5" height="1" fill="white"/>
        </svg>
    )
}
